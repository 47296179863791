<template>
	<div class="kontakt">

		<h1>Kontakt</h1>


<p><b>Muzeum im. ks. dr. Władysława Łęgi w Grudziądzu</b></p>
<p><a href="https://goo.gl/maps/6TjghsnbGKa61RFHA">ul. Wodna 3/5,<br /> 86-300 Grudziądz</a></p>
<p><a href="http://www.muzeum.grudziadz.pl">www.muzeum.grudziadz.pl</a></p>
<p><a href="tel:+48564659063#15">tel.56-46-590-63 wew. 15</a></p>
<p><a href="mailto:sztuka@muzeum.grudziadz.pl">sztuka@muzeum.grudziadz.pl</a></p>
<p><a href="https://www.facebook.com/Grudzi%C4%85dzka-strefa-kultury-101552541928635">Facebook</a></p>

	</div>
</template>

<script>

// console.log('licomponent', licomponent);

export default {
	name: 'kontakt',


	data() {
		return {
			loading: true,
			error: false,
			msg: 'Welcome to my Vuex Store',
		};
	},

	computed: {

	},
	mounted() {


	},


};
</script>

<style lang="less">
</style>